import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDHWAD9Z-rhwE3SXV03a3pNY87Yyai10lg",
    authDomain: "cghm-4a681.firebaseapp.com",
    projectId: "cghm-4a681",
    storageBucket: "cghm-4a681.appspot.com",
    messagingSenderId: "914703804726",
    appId: "1:914703804726:web:8f6f9ee7fcd44f5a40ab97",
    measurementId: "G-P0XF9M02EM"
};
  
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const auth = getAuth(app);


export {
    firestore,
    auth
};
