import React, { useContext } from 'react';
import { Outlet } from "react-router-dom";
import { SidebarItem } from "../components";
import Constants, { ConstantKeys } from "../Constants";
import { UserContext } from '../contexts/UserContext';

const Sidebar = () => {
    const { currentUser } = useContext(UserContext)!;
    const constantKeys: ConstantKeys[]= ["STOCKS", "USERS", "ARTICLES", "SALES", "STATS", "INVENTORIES", "SHOPS", "TAG_PRICE"];
    const items = constantKeys.map((c: ConstantKeys) => ({ name: Constants[c].title.fr, link: Constants[c].link}));
    
    return (
        <div className="h-full flex w-full">
            <div className="flex-none h-full bg-blue-mysell p-4">
                <div className="space-y-4">
                    <div className="rounded border border-white p-1 text-xs text-white">
                        {currentUser?.company.city || "Inconnu"}
                    </div>
                    <div className="rounded border border-white p-1 text-xs text-white">
                        {currentUser?.userData.role || "Inconnu"}
                    </div>
                </div>

                <div>
                    {items.map(({ name, link }) => <SidebarItem key={name} name={name} link={link}/> )}
                </div>
            </div>
            <div className="grow w-1/4 h-full overflow-y-auto">
                <Outlet />
            </div>
        </div>
    );
};

export default Sidebar;