import { createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword, signOut, User } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { createContext, useState, useEffect, ReactNode, SetStateAction, Dispatch } from "react";
import { companyConverter, userDataConverter } from "../converters";
import { auth, firestore } from "../firebase.config";
import useFetching from "../hooks/useFetching";
import { Company, UserData } from "../models";

type Props= {
    children: ReactNode
}
type UserInfo= {
    userAuth: User|null,
    userData: UserData,
    company: Company
}

type UserContextType = {
    fetching: any, 
    currentUser: UserInfo | null,
    toggleUser: Dispatch<SetStateAction<UserInfo|null>>,
    signUp: Function,
    signIn: Function,
    logOut: Function
}

const UserContext = createContext<UserContextType | null>(null);

const UserContextProvider = ({children}: Props) => {
    const [currentUser, setCurrentUser] = useState<UserInfo|null>(null);
    const fetching = useFetching();

    const signUp = (email: string, pwd: string) => createUserWithEmailAndPassword(auth, email, pwd);

    const signIn = (email: string, pwd: string) => {
        fetching.start();
        signInWithEmailAndPassword(auth, email, pwd).then((UserCredential) =>{
            fetching.stop();
        }).catch((error) => {
            fetching.stop(error);
        })
    }
    const logOut = () => {
        fetching.start();
        signOut(auth).then(() => fetching.stop()).catch((error) => {
            alert(`La déconnexion n'a pas réussi : ${error}`);
            fetching.stop(error);
        })
    }

    useEffect(() => {
        fetching.start();
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            
            if(user !== (null && undefined)){
                const userDataRef = doc(firestore, "usersData", user.uid).withConverter(userDataConverter);
                const userData = (await getDoc(userDataRef)).data() as UserData;
                const companyRef = doc(firestore, "companies", userData.companyId).withConverter(companyConverter);
                const company = (await getDoc(companyRef)).data() as Company;
                
                setCurrentUser({
                    userAuth: user,
                    userData: userData,
                    company
                });
            }
            fetching.stop(user == null?"Aucun utilisateur connecté": undefined);
        });
        return unsubscribe;
    //eslint-disable-next-line
    }, [])

    return (
        <UserContext.Provider value={{ fetching, currentUser, toggleUser: setCurrentUser, signUp, signIn, logOut }}>
            {children}
        </UserContext.Provider>
    );
};

export {
    UserContext,
    UserContextProvider
};