import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { FormatsAndProducts } from '../models';
import { Font } from '@react-pdf/renderer';
import fontCodeBarre from './fonts/c39hrp48dhtt.ttf';
import interBold from './fonts/Inter/static/Inter-Bold.ttf';
import interMedium from './fonts/Inter/static/Inter-Medium.ttf';
import interRegular from './fonts/Inter/static/Inter-Regular.ttf';
import interLight from './fonts/Inter/static/Inter-Light.ttf';
import inter from './fonts/Inter/Inter-VariableFont_slnt,wght.ttf';

Font.register({ 
  family: 'CodeFamily',
  format: "truetype",
  src: fontCodeBarre
});

Font.register({ 
  family: 'InterBold',
  format: "truetype",
  src: interBold
});

Font.register({ 
  family: 'Inter',
  format: "truetype",
  src: inter
});

Font.register({ 
  family: 'InterMedium',
  format: "truetype",
  src: interMedium
});

Font.register({ 
  family: 'InterRegular',
  format: "truetype",
  src: interRegular
});

Font.register({ 
  family: 'InterLight',
  format: "truetype",
  src: interLight
});

const R = require('ramda');

type Props = {
    formatsAndProducts: FormatsAndProducts
}

const Pdf = ({ formatsAndProducts: { formats = [], products = []} }: Props) => {
  const styles = StyleSheet.create({
    page:{
        flexDirection: 'row',
        flexWrap: 'wrap',
        fontFamily: "Inter"
    },
    "tag-grand": {
      flexDirection: "column",
      width: "50%",
      height: "20%",
      border: "1px black solid",
      padding: 2
    },
    "tag-petit": {
      flexDirection: "column",
      width: "25%",
      height: "12.5%",
      border: "1px black solid",
      padding: 2
    },
    "tag-coller": {
      flexDirection: "column",
      width: "50%",
      height: "16.66%",
      border: "1px black solid",
      padding: 2
    },
    "productName-grand": { fontSize: 15, fontFamily: "InterMedium" },
    "productBrand-grand": { fontSize: 17, fontFamily: "InterBold" },
    "productReference-grand": { fontSize: 12, fontFamily: "InterLight", marginTop: 3 },
    "productSalePrice-grand": { fontSize: 60, fontFamily: "InterBold" },
    "productSalePriceAfterDot-grand": { fontSize: 30, fontFamily: "InterBold" },
    "garanty-grand": { fontSize: 10, fontFamily: "InterLight" },
    "productEan-grand": { fontSize: 30, fontFamily: "CodeFamily", paddingBottom: 10 },
    "productName-petit": {fontSize: 8, fontFamily: "InterMedium"},
    "productBrand-petit": { fontSize: 8, fontFamily: "InterBold" },
    "productReference-petit": { fontSize: 6, fontFamily: "InterLight", marginTop: 3 },
    "productSalePrice-petit": { fontSize: 36, fontFamily: "InterBold" },
    "productSalePriceAfterDot-petit": { fontSize: 15, fontFamily: "InterBold" },
    "garanty-petit": { fontSize: 5, fontFamily: "InterLight" },
    "productEan-petit": { fontSize: 12, fontFamily: "CodeFamily", paddingBottom: 5 },
    "productName-coller": {fontSize: 13, fontFamily: "InterMedium"},
    "productBrand-coller": { fontSize: 15, fontFamily: "InterBold" },
    "productReference-coller": { fontSize: 10, fontFamily: "InterLight", marginTop: 3 },
    "productSalePrice-coller": { fontSize: 50, fontFamily: "InterBold" },
    "productSalePriceAfterDot-coller": { fontSize: 25, fontFamily: "InterBold" },
    "garanty-coller": { fontSize: 10, fontFamily: "InterLight" },
    "productEan-coller": { fontSize: 30, fontFamily: "CodeFamily", paddingBottom: 10 }
  });

  return (
    <Document>
    { formats.map((format) => (
      <Page size="A4" style={styles.page} key={"page-"+format}>
        {products.map((product) => (
            <View style={styles[`tag-${format}`]} key={format+"_pdf_"+product.ean}>
                <Text style={styles[`productName-${format}`]}>{product.name}</Text>
                <View style={{ borderBottomColor: 'black', borderBottomWidth: 1, marginTop: 5, marginBottom: 5}}/>
                <Text style={styles[`productBrand-${format}`]}>{product.brand}</Text>
                <Text style={styles[`productReference-${format}`]}>{product.reference}</Text>
                <View style={{ flexDirection: "row", justifyContent: "flex-end", flexFlow: 1}}>
                  <Text style={styles[`productSalePrice-${format}`]}>{R.compose(R.prop(0), R.split('.'))(product.salePrice.toString())}</Text>
                  <Text style={styles[`productSalePriceAfterDot-${format}`]}>{R.compose(R.concat('€'),R.propOr('', 1), R.split('.'))(product.salePrice.toString())}</Text>
                </View>
                <View style={{ marginTop: 10, flexDirection: "row", justifyContent: "space-between" }}>
                  <View style={styles[`garanty-${format}`]}>
                    <Text>Dont 0.20€ éco participation</Text>
                    <Text>Produit garantie 2 ans</Text>
                  </View>
                  { format === "coller" && <Text style={{ fontSize: 30,fontFamily: "CodeFamily" }}>10036</Text>}
                  <Text style={styles[`productEan-${format}`]}>{product.ean}</Text>
                </View>
            </View>
        ))}
      </Page>
    ))}
  </Document>
  );
};

export default Pdf;