import React from 'react';

const Home = () => {
    return (
        <div>
            La Home page
        </div>
    );
};


export default Home;