import React from 'react';
import Modal from '.';
import { useProduct } from "../../hooks";
import { Product } from "../../models";
import InputField from '../InputField';

type Props = {
    isOpen: Boolean,
    onClickOpenClose: Function,
};

const AddProduct = ({ isOpen, onClickOpenClose }: Props) => {
    const { addProducts } = useProduct();

    const getData = (formData: FormData) => {
        const product = (Object.fromEntries(formData) as unknown) as Product;
        if(product != null) addProducts([product]);
    }
    
    return (
        <Modal isOpen={isOpen} onClickOpenClose={onClickOpenClose} title={"Nouvelle article"} getData={getData}>
            <InputField titleClassName="text-blue-mysell" name="Dénomination complète" type="text" slug="name"/>
            <InputField titleClassName="text-blue-mysell" name="EAN" type="text" slug="ean" maxLength={13} minLength={13}/>
            <div className="flex justify-between">
                <InputField className="w-1/2 pr-2" step="0.01" titleClassName="text-blue-mysell" name="Prix de vente" type="number" slug="salePrice"/>
                <InputField className="w-1/2 pl-2" step="0.01" titleClassName="text-blue-mysell" name="Prix d'achat" type="number" slug="purchasePrice"/>
            </div>
            <div className="flex justify-between">
                <InputField className="w-1/2 pr-2" titleClassName="text-blue-mysell" step="0.01" name="Quantité" type="number" slug="quantity"/>
                <InputField className="w-1/2 pl-2" titleClassName="text-blue-mysell" step="0.01" name="Montant DEEE" type="number" slug="deee"/>
            </div>
        </Modal>
    );
};

export default AddProduct;