import { useContext, useState } from "react";
import { collection, doc, getDoc, getDocs, writeBatch } from "firebase/firestore";
import { firestore } from "../firebase.config";
import { productConverter } from "../converters";
import useFetching from "./useFetching";
import { Product } from "../models";
import { UserContext } from "../contexts/UserContext";

const useProduct = () => {
    const { currentUser } = useContext(UserContext)!;
    const [products, setProducts] = useState<Product[]>([]);
    const collectionName = `companies/${currentUser?.userData.companyId}/products`;
    const collectionProducts = collection(firestore, collectionName).withConverter(productConverter);
    const fetching = useFetching();

    const getProduct = async (ean: String): Promise<Product | null> => {
        const product = (await getDoc(doc(firestore, collectionName, ean.toString()).withConverter(productConverter))).data()
        return product || null;
    } 
    const getProducts = async () => {
        const listProducts: Product[] = [];
        (await getDocs(collectionProducts)).forEach(doc => listProducts.push(doc.data()));
        setProducts(listProducts);
    }
    const addProducts = async (productsToAdd: Product[]) => {
        const batch = writeBatch(firestore);
        fetching.start();
        if (productsToAdd.length > 500){
            fetching.stop("Le nombre maximum de produit est 500.");
            return;
        }
        for(const product of productsToAdd) {
            const productRef = doc(firestore, collectionName, product.ean.toString()).withConverter(productConverter);
            const productEntity = await getDoc(productRef);
            if (productEntity.exists()) {
                batch.set(productRef, { ...product, quantity: productEntity.data().quantity + product.quantity})
            }else{
                batch.set(productRef, product);
            }
        }
        batch.commit().then(() => {
            fetching.stop();
            getProducts();
        }).catch(console.log);
    }

    return {
        collectionProducts,
        products,
        getProducts,
        getProduct,
        addProducts
    };
};

export default useProduct;