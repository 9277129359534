import React, { FormEvent, MouseEvent } from 'react';
import AddProduct from "./AddProduct";

export {
    AddProduct
};

type Props = {
    getData: Function, 
    isOpen: Boolean,
    onClickOpenClose: Function,
    title: string,
    children: string | JSX.Element | JSX.Element[]
};

const Modal = ({ getData, isOpen, onClickOpenClose, title, children }: Props) => {
    const onClickStopPropagation = (e: MouseEvent<HTMLElement>) => e.stopPropagation();

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        getData(formData);
        e.currentTarget.reset();
        onClickOpenClose();
    };
    return (
        <div 
        className="bg-light-blue-mysell bg-opacity-70 z-50 absolute justify-center items-center w-full h-full left-0 top-0"
        onClick={() => onClickOpenClose()}     
        style={{display: isOpen?"flex":"none"}} >
            <div className={`w-1/4 bg-light-blue-mysell p-5 shadow`} onClick={onClickStopPropagation}>
                <h2 className="text-center text-md text-blue-mysell px-8">{title}</h2>
                <form className="space-y-2" onSubmit={onSubmit}>
                    {children}
                    <div className="flex justify-around flex-wrap space-y-1">
                        <input className="btn-mysell-red mt-1" onClick={() => onClickOpenClose()} type="button" value="ANNULER"/>
                        <input className="btn-mysell-green" type="submit" value="AJOUTER"/>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Modal;