import React, { FormEvent, useState } from 'react';
import { useProduct } from '../hooks';

type Props = {
    onSubmit: Function,
    canUseEan: Function
};

const SearchByEAN = ({ onSubmit, canUseEan }: Props) => {
    const [ean, setEan] = useState("");
    const [isErrorEan, setIsErrorEan] = useState(false);
    const { getProduct } = useProduct();
    const R = require('ramda');
    
    const eanSubmit = async (event: FormEvent<HTMLFormElement>)=> {
        event.preventDefault();
        setIsErrorEan(false);
        if (!R.isEmpty(ean) && R.length(ean) === 13){
            if (canUseEan(ean)){
                const product = await getProduct(ean);
                console.log(product);
                if(product !== null){
                    if(R.is(Function, onSubmit)) onSubmit(product);
                    setEan('');
                    return;
                
                }
            }
        }
        setIsErrorEan(true);
    }
    return (
        <div>
            <form onSubmit={eanSubmit}>
                <div className='w-full flex flex-col items-center'>
                    <div className='relative flex flex-col mt-1 justify-center w-1/4'>
                        <input type={'number'}
                            placeholder="ean.."
                            className="bg-white pl-2 text-sm rounded-full text-blue-mysell"
                            name={'ean'}
                            minLength={13}
                            maxLength={13}
                            value={ean}
                            onChange={(e) => setEan(e.currentTarget.value)}
                            required/>
                        <svg className='absolute right-0 top-1/2 -translate-x-1/2 -translate-y-1/2' width="15" height="15" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.9268 17.0401L20.4 20.4001M11.4 7.2001C13.3882 7.2001 15 8.81187 15 10.8001M19.28 11.4401C19.28 15.77 15.7699 19.2801 11.44 19.2801C7.11006 19.2801 3.59998 15.77 3.59998 11.4401C3.59998 7.11018 7.11006 3.6001 11.44 3.6001C15.7699 3.6001 19.28 7.11018 19.28 11.4401Z" stroke="black" strokeWidth="2" strokeLinecap="round"/>
                        </svg>
                    </div>
                    {isErrorEan && <div className='text-red-mysell text-sm'>Code barre déjà utilisé ou incorrect.</div>}
                </div>
            </form>
        </div>
    );
};

export default SearchByEAN;