import { QueryDocumentSnapshot, SnapshotOptions } from "firebase/firestore";
import { Company } from "../models";

export const companyConverter = {
    toFirestore: (company: Company) => {
        return {
            city: company.city,
            name: company.name
        };
    },
    fromFirestore: (snapshot: QueryDocumentSnapshot, options?: SnapshotOptions) => {
        const companyData: Company = (snapshot.data(options) as unknown) as Company;
        return companyData;
    }
};