import React, { FormEvent, useEffect, useState } from 'react';
import { HeaderPage, SearchByEAN } from "../components";
import Table from '../components/Table';
import { salesProduct } from '../components/Table/columns/salesProduct';
import Constants from "../Constants";
import { Product, ProductWithTrashAndDiscountAndSelectedQuantity } from '../models';

const Sales = () => {
    const [productsSelect, setProductsSelect] = useState<ProductWithTrashAndDiscountAndSelectedQuantity[]>([]);
    const [total, setTotal] = useState<number>(0);
    const R = require('ramda');

    useEffect(() => {
        setTotal(productsSelect.reduce((partialSum, a) => partialSum + ((a.selectedQuantity|| 0)*a.product.salePrice), 0))
    }, [productsSelect])
    

    const setSelectedQuantity = function(this: ProductWithTrashAndDiscountAndSelectedQuantity, quantity: number){
        console.log(this, quantity);
        setProductsSelect(current => current.map(p => p === this ? { ...p, selectedQuantity: quantity} : p));
    }
    
    
    
    const canUseEan = (ean: String) => R.T();
    const onClickOnTrash = (id: string) => () => setProductsSelect((current) => current.filter(v => id !== v.product.id));
    const onClickOnDiscount = (product: ProductWithTrashAndDiscountAndSelectedQuantity) => () => console.log(product);

    
    const onSubmitSearchProduct = (product: Product) => {
        setProductsSelect([...productsSelect, 
                { 
                    id: product.id,
                    product,
                    onClickOnTrash, 
                    onClickOnDiscount,
                    setSelectedQuantity,
                    selectedQuantity: 1
                }])
    }

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        console.log(productsSelect);
    }
    return (
        <>
            <HeaderPage name={Constants.SALES.title.fr}>
                <SearchByEAN canUseEan={canUseEan} onSubmit={onSubmitSearchProduct}/>
            </HeaderPage>
            <form className='my-3 px-6' onSubmit={onSubmit}>
                <Table data={productsSelect} columns={salesProduct} withCross={false} />
                <div className='flex justify-end mt-6 pr-12'>
                    <table>
                        <tbody>
                            <tr>
                                <td className='bg-red-mysell text-center px-12 text-white p-4 font-bold'>Sous-Total</td>
                                <td className='bg-[#D9D9D9] text-blue-mysell p-4 px-12 text-center'>{total.toFixed(2)}€</td>
                            </tr>
                            <tr>
                                <td className='text-center p-4'><button className='btn-mysell-red'>ANNULER</button></td>
                                <td className='text-center p-4'><button type='submit' className='btn-mysell-green'>VALIDER</button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </form>
        </>
    );
};

export default Sales;