import { QueryDocumentSnapshot, SnapshotOptions } from "firebase/firestore";
import { Product } from "../models";

export const productConverter = {
    toFirestore: (product: Product) => {
        return {
            ean: product.ean,
            reference: product.reference || "",
            name: product.name,
            brand: product.brand || "",
            initStock: product.initStock || 0,
            quantity: product.quantity || 0,
            salePrice: product.salePrice || 0,
            purchasePrice: product.purchasePrice || 0,
            deee: product.deee,
        };
    },
    fromFirestore: (snapshot: QueryDocumentSnapshot, options?: SnapshotOptions) => {
        const product: Product = Object.assign(new Product(), snapshot.data(options) as unknown);
        product.id = snapshot.id;
        return product;
    }
};