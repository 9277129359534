export type ConstantKeys = "STOCKS"|"USERS"|"ARTICLES"| "SALES"|"STATS"|"INVENTORIES"|"SHOPS"|"TAG_PRICE";
export type ConstantValue = {
    title: {fr: string},
    link: string
}
type Constant = {
    [key in ConstantKeys]: ConstantValue
}
const Constants: Constant = {
    STOCKS: {
        title: {fr: "Gestion des stocks"},
        link: "/stocks"
    },
    ARTICLES: {
        title: {fr: "Reprise d'articles"},
        link: "/articles"
    },
    SALES:{
        title: {fr: "Création de ventes"},
        link: "/ventes"
    },
    STATS:{
        title: {fr: "Statistiques"},
        link: "/stats"
    },
    INVENTORIES:{
        title: {fr: "Inventaires"},
        link: "/inventaires"
    },
    SHOPS:{
        title: {fr: "Gestion des magasins"},
        link: "/magasins"
    },
    USERS:{
        title: {fr: "Gestion des utilisateurs"},
        link: "/users"
    },
    TAG_PRICE:{
        title:{fr: "Etiquette de prix"},
        link: "/tag-price"
    } 
};

export default Constants;