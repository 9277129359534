import React from 'react';
import { HeaderPage } from "../components";
import Constants from "../Constants";

const Inventories = () => {
    return (
        <>
            <HeaderPage name={Constants.INVENTORIES.title.fr + " par famille"}/>
        </>
    );
};

export default Inventories;