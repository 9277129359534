import React from 'react';

type Props = {
    name: string,
    slug: string,
    step?: string, 
    type?: string,
    onChange?: Function,
    value?: string,
    maxLength?: number,
    minLength?: number,
    titleClassName?: string,
    className?: string
}
const InputField = ({ name, slug, step = "", type = "text", onChange = () =>{}, value, maxLength, minLength, titleClassName = "", className = "" }: Props) => {
    return (
        <div className={`flex flex-col ${className}`}>
            <label htmlFor={slug} className={`text-xs pl-2 ${titleClassName}`}>{name}</label>
            <input type={type}
                className="pl-2 mt-1 text-sm rounded-full text-blue-mysell"
                name={slug} 
                value={value} 
                step={step}
                maxLength={maxLength}
                minLength={minLength}
                onChange={(e) => onChange(e.target.value)} 
                required/>
        </div>
    );
};

export default InputField;