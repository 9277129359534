import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Constants from "./Constants";
import { ProtectedRoute, Sidebar } from "./layouts";
import { Articles, Error404, Home, Inventories, Login, Sales, Shops, Stats, Stocks, TagPrice, Users } from './pages';

const Navigation= () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<ProtectedRoute/>}>
          <Route path="/" element={<Sidebar />} >
            <Route index element={<Home/>}/>
            <Route path={Constants.STOCKS.link} element={<Stocks/>}/>
            <Route path={Constants.ARTICLES.link} element={<Articles/>}/>
            <Route path={Constants.SALES.link} element={<Sales/>}/>
            <Route path={Constants.SHOPS.link} element={<Shops/>}/>
            <Route path={Constants.STATS.link} element={<Stats/>}/>
            <Route path={Constants.INVENTORIES.link} element={<Inventories/>}/>
            <Route path={Constants.USERS.link} element={<Users/>}/>
            <Route path={Constants.TAG_PRICE.link} element={<TagPrice/>}/>
          </Route>
        </Route>
        <Route path="/login" element={<Login/>}/>
        <Route path="*" element={<Error404/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default Navigation;