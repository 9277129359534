import { QueryDocumentSnapshot, SnapshotOptions } from "firebase/firestore";
import { UserData } from "../models";

export const userDataConverter = {
    toFirestore: (user: UserData) => {
        return {
            role: user.role,
            name: user.name,
            companyId: user.companyId,
        };
    },
    fromFirestore: (snapshot: QueryDocumentSnapshot, options?: SnapshotOptions) => {
        const userData: UserData = (snapshot.data(options) as unknown) as UserData;
        return userData;
    }
};