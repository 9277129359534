import React, { ReactNode } from 'react';

type Props = {
    name: string,
    children?: ReactNode
};

const HeaderPage = ({ name, children }: Props) => {
    return (
        <div className="flex flex-col items-center space-y-3 bg-light-blue-mysell p-4 pb-8 shadow-xl">
            <h1 className="text-4xl font-bold text-blue-mysell">{name}</h1>
            <hr className="w-2/3 border-2 border-blue-mysell rounded-full"/>
            <div className="w-full">
                {children}
            </div>
        </div>
    );
};

export default HeaderPage;