import React from 'react';
import { HeaderPage } from "../components";
import Constants from "../Constants";

const Shops = () => {
    return (
        <>
            <HeaderPage name={Constants.SHOPS.title.fr}/>
        </>
    );
};

export default Shops;