import { usePDF } from '@react-pdf/renderer';
import React, { FormEvent, useEffect, useState } from 'react';
import { HeaderPage, SearchByEAN, TagFormat } from '../components';
import Table from '../components/Table';
import { tagProduct } from '../components/Table/columns/tagProduct';
import Constants from '../Constants';
import { FormatsAndProducts, Product, ProductWithTrash } from '../models';
import Pdf from '../pdfs/Pdf';

const R = require('ramda');
const RA = require('ramda-adjunct');



const TagPrice = () => {
    const [productsSelect, setProductsSelect] = useState<ProductWithTrash[]>([]);
    const [formatAndProducts, setFormatsAndProducts] = useState<FormatsAndProducts>({ formats: [], products: []});
    const [isErrorFormats, setIsErrorFormats] = useState(false);
    const [isErrorProducts, setIsErrorProducts] = useState(false);
    const document = <Pdf formatsAndProducts={formatAndProducts}/>;
    const [pdf, update] = usePDF({ document });
    const [PdfUrl, setPdfUrl] = useState(pdf.url);
    
    const loadPdf = () => {
        if(!R.isEmpty(formatAndProducts.formats) && !R.isEmpty(formatAndProducts.products) && PdfUrl != null){
            window.open(PdfUrl);
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(update, [formatAndProducts])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => setPdfUrl(pdf.url), [pdf])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(loadPdf, [PdfUrl])

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsErrorFormats(false);
        setIsErrorProducts(false);
        const data = new FormData(e.currentTarget);
        const formatSelect = R.filter(RA.isNotEmpty, R.split(',')(data.get("formats")));
        if(R.isEmpty(formatSelect)){
            setIsErrorFormats(true);
            return;
        }
        if(R.isEmpty(productsSelect)){
            setIsErrorProducts(true);
            return;
        }
        const eqValues = (a1: Product[], a2: Product[]) => R.equals(new Set(a1), new Set(a2));        ;
        if(R.equals(formatSelect, formatAndProducts.formats) && eqValues(productsSelect.map(p => p.product), formatAndProducts.products)) {
            loadPdf();
            return;
        }
        setFormatsAndProducts({
            formats: formatSelect,
            products: productsSelect.map((p) => p.product)
        });
    }
    const canUseEan = (ean: String) => R.isEmpty(R.filter((n: ProductWithTrash) => n.product.id === ean, productsSelect))
    const onClickTrash = (id: string) => () => setProductsSelect((current) => current.filter(v => id !== v.product.id));
    const onSubmitSearchProduct = (product: Product) => {
        setProductsSelect([...productsSelect, { id: product.id, product, onClickOnTrash: onClickTrash}])
    }

    return (
        <>
            <HeaderPage name={Constants.TAG_PRICE.title.fr}>
                <SearchByEAN  canUseEan={canUseEan} onSubmit={onSubmitSearchProduct}/>
            </HeaderPage>
            <form onSubmit={onSubmit}>
                <div className='px-6'>
                    <div className='my-3'>
                        <div className='flex'>
                            <div className='flex-1 w-full'>
                                <Table columns={tagProduct} data={productsSelect} withCross={false}/>
                                {isErrorProducts && <div className='text-red-mysell text-sm'>Aucun article sélectionné.</div>}
                            </div>
                            <div className='ml-3'>
                                <TagFormat/> 
                                {isErrorFormats && <div className='text-red-mysell text-sm'>Veuillez sélectionner au moins 1 format.</div>}
                                <div className='text-center my-3'>
                                    <input className='btn-mysell-green' type={'submit'} value={'Imprimer'}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
       </>
        
    );
};

export default TagPrice;