export type Column = {
    name: Function | string,
    field: Function | string
};

export type UserData = {
    id?: string,
    role: string,
    companyId: string,
    name: string
}

export type Company = {
    city: string,
    name: string
}

export class Product {
    id?: string;
    reference: string|null = null;
    ean: string = "";
    name: string = "";
    brand: string = "";
    initStock: number = 0;
    quantity: number = 0;
    salePrice: number = 0;
    purchasePrice: number = 0;
    deee: number = 0;
};

export type ProductWithTrash = {
    id?: string,
    product: Product,
    onClickOnTrash: Function
};

export interface ProductWithTrashAndDiscount extends ProductWithTrash {
    onClickOnDiscount: Function
}

export interface ProductWithTrashAndDiscountAndSelectedQuantity extends ProductWithTrashAndDiscount {
    selectedQuantity: number,
    setSelectedQuantity: Function
}

export type Format = "grand"|"petit"|"coller";

export type FormatsAndProducts = {
    formats: Format[],
    products: Product[]
}
