import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { UserContext } from "../contexts/UserContext";

const ProtectedRoute = ({
    redirectPath = '/login',
  }) => {   
    const { currentUser, fetching } = useContext(UserContext)!;
    if (currentUser?.userAuth && fetching.isDone()) return <Outlet/>;
    if (fetching.isDoneWithError()) return <Navigate to={redirectPath} replace />;
    return <h1>Chargement</h1>;
};

export default ProtectedRoute;