import React, { ChangeEvent } from 'react';
import Papa from 'papaparse';
import { Product } from '../models';
import { useProduct } from '../hooks';

const ImportData = () => {
    const { addProducts } = useProduct();

    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        if(e.currentTarget.files !== null){
          Papa.parse(e.currentTarget.files[0], {
            header: true,
            skipEmptyLines: true,
            dynamicTyping: true,
            complete: (results) => {
              addProducts(results.data.map((r) => Object.assign(new Product(), r)));
            },
          });
          
        }
      };
    return (
        <input
            type={"file"}
            id={"csvFileInput"}
            accept={".csv"}
            onChange={handleOnChange}
          />
    );
};

export default ImportData;