import React, { useState } from 'react';
import Table from './Table';
import { Format as Id } from '../models';

type Format = {
    name: String,
    id: Id,
    onClick: Function
};

type Props = {
    onChange?: Function
};

const TagFormat = ({ onChange = (value: Id[]) => {}}: Props) => {

    const [formatsSelect, setFormatsSelect] = useState<Id[]>([]);
    const onClick = (id: Id, isChecked: boolean) => {
        if(isChecked) {
            setFormatsSelect([...formatsSelect, id]);
        }else{
            setFormatsSelect(formatsSelect.filter((value) => value !== id))
        }
        onChange(formatsSelect);
    }
    const formats: Format[] = [{
        name: "Grand format",
        id: "grand",
        onClick
    }, {
        name: "Petit format",
        id: "petit",
        onClick
    }, {
        name: "Etiquette à coller",
        id: "coller",
        onClick
    }];

    const columns = [{
        "name": "Format",
        "field": (item: Format) => <h1 className="text-blue-mysell flex justify-between">{item.name} <input className='ml-2' onChange={(e)=>item.onClick(item.id, e.currentTarget.checked)} type={'checkbox'} checked={formatsSelect.includes(item.id)}/></h1>
    }]
    return (
        <>
            <Table columns={columns} data={formats} withCross={false}/>
            <input name={'formats'} type={'hidden'} value={formatsSelect} multiple={true} required={true} readOnly/>
        </>
    );
};

export default TagFormat;