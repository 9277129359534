import React, { FormEvent, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { InputField } from "../components";
import { UserContext } from "../contexts/UserContext";

const Login = () => {
    const navigate = useNavigate();
    const { signIn, logOut } = useContext(UserContext)!;
    
    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        signIn(data.get("email"), data.get("password"));
        navigate('/');
    };

    return (
        <div className="h-full flex justify-center items-center">
            <div className="w-2/3 max-w-2xl p-10 bg-blue-mysell">
                <h1 className="text-center text-white text-2xl">Connexion MySell</h1>
                <form onSubmit={handleSubmit} className="space-y-10">
                    <InputField titleClassName="text-white" name="Email" type="email" slug="email" />
                    <InputField titleClassName="text-white" name="Mot de passe" type="password" slug="password"/>
                    <div className="text-center">
                        <input className="btn-mysell-green" type="submit" value="sign in"/>
                    </div>
                </form>
                <button onClick={() => logOut()}>LogOut</button>
            </div>
        </div>
    );
};


export default Login;