import { useState } from 'react';
const R = require('ramda');

type State = { 
  status: string,
  error?: string, 
  payload: null 
}
const useFetching = (initialStatus: string = "standby", successCb?: any, errorCb?: any) => {
  const initialState = { status: initialStatus, error: undefined, payload: null };
  const [state, setState] = useState<State>(initialState);

  return {
    reset: () => {
      setState(initialState);
    },
    start: () => {
      setState(R.assoc('status', 'fetching', initialState));
    },
    stop: (error?: string, payload?: any) => {
      if (R.isNil(error)) {
        if (R.is(Function, successCb)) successCb(payload);
        setState({ status: 'done',error: undefined, payload });
      } else {
        if (R.is(Function, errorCb)) successCb(error);
        setState({ status: 'error', error: error!, payload: null });
      }
    },
    statusIs: (status: string) => R.equals(status, R.prop('status', state)),
    statusIsNot: (status: string) => R.not(R.equals(status, R.prop('status', state))),
    isDone: () => R.propEq('status', 'done', state),
    isDoneWithError: () => R.propEq('status', 'error', state),
    isNotDone: () => R.not(R.propEq('status', 'done', state)),
    getError: () => R.prop('error', state),
    getStatus: () => R.prop('status', state),
    getPayload: () => R.prop('payload', state),
    isFetching: () => R.propEq('status', 'fetching', state)
  };
};

export default useFetching;
