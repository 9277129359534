import React from 'react';
import { HeaderPage } from "../components";
import Constants from "../Constants";

const Users = () => {
    return (
        <>
            <HeaderPage name={Constants.USERS.title.fr}/>
        </>
    );
};

export default Users;