import React, { useState} from 'react';
import { Column } from '../../models';

type Props = {
    columns: Array<Column>,
    data: Array<any>,
    onClickCross?: Function,
    isClicked?: Boolean,
    withCross?: Boolean
};
const Table = ({columns, data, onClickCross, isClicked = false, withCross = true}: Props) => {
    const [openModal, setOpenModal] = useState(isClicked);
    const onClick = (value: Boolean) => {
        setOpenModal(value);
        if (onClickCross instanceof Function) onClickCross();
    }
    return (
        <div className={`${withCross?"px-6":""} overflow-x-auto`}>
            {
                withCross && (
                    <div>
                        <div onClick={() => onClick(!openModal)} className="w-fit -translate-x-1/2 translate-y-1/2 bg-red-mysell p-3 rounded-full shadow">
                            <svg width="20" height="18" viewBox="0 0 20 18" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.2198 8.00306H10.9541L10.9541 0.0976065H8.86913L8.86913 8.00306H0.603342L0.603342 9.9971L8.86913 9.9971V17.9026H10.9541V9.9971L19.2198 9.9971L19.2198 8.00306Z" fill="white"/>
                            </svg>
                        </div>
                    </div>
                )
            }
            <table className="min-w-full">
                <thead>
                    <tr>
                        {columns.map(({name}, index) => <th className="bg-red-mysell text-center text-white p-4 " key={"column-"+index+"-"+(name instanceof Function? name(): name)}>{name instanceof Function? name(): name}</th>)}
                    </tr>
                </thead>
                <tbody id="tbody" className="">
                    {data.map((item, key)=> {
                        return (
                        <tr key={"row-"+item.id+"-"+key}>
                            {columns.map((column, index)=> <td className="text-blue-mysell p-4 text-center" key={"row-"+index+"-"+item.id}>{column.field instanceof Function? column.field(item): item[column.field]}</td>)}
                        </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};


export default Table;