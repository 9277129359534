import React from 'react';
import { Link } from "react-router-dom";

type Props = {
    name: string,
    logo?: string,
    link: string
}

const SidebarItem = ({name, logo, link}: Props) => {
    return (
        <Link to={link}>
            <div className="p-1 my-4 text-xs text-white hover:bg-white hover:text-blue-mysell hover:rounded">
                {logo}<span>{name}</span>
            </div>
        </Link>
    );
};

export default SidebarItem;