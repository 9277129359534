
import { useEffect, useState } from 'react';
import { HeaderPage, ImportData } from "../components";
import { AddProduct } from "../components/modals";
import Table from "../components/Table";
import { stock as columns } from "../components/Table/columns/stock";
import Constants from "../Constants";
import useProduct from "../hooks/useProduct";

const Stocks = () => {
    const [isOpen, setIsOpen] = useState(false);
    const onClickIsOpen = () => setIsOpen(!isOpen);
    const { products, getProducts } = useProduct();
    
    
    useEffect(() => {        
        getProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <HeaderPage name={Constants.STOCKS.title.fr}>
               <div className='flex justify-around'>
                    <ImportData />
                    <a className='p-1 border border-blue-mysell rounded hover:bg-blue-mysell hover:text-white' href='/import-vierge.csv'>fichier vierge</a>
               </div>
            </HeaderPage>
            <Table data={products} columns={columns} onClickCross={onClickIsOpen} isClicked={isOpen}/>
            <AddProduct isOpen={isOpen} onClickOpenClose={onClickIsOpen}/>
        </>
    );
};

export default Stocks;