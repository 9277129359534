import { Product } from "../../../models";

export const stock = [{
    "name": "EAN",
    "field": "ean"
},{
    "name": "Dénomination",
    "field": "name"
},{
    "name": "Quantité",
    "field": "quantity"
},{
    "name": "Prix d'achat",
    "field": (item: Product) => item.purchasePrice+"€"
},{
    "name": "Prix de vente",
    "field": (item: Product) => item.salePrice+"€"
},{
    "name": "Total",
    "field": (item: Product) => (item.salePrice*item.quantity).toFixed(2)+"€"
}]